<template lang="pug">
ProductThirdPartyCoverking(v-if='brandName === "Coverking" || brandName === "Skanda"' :params='integration.params')
ProductThirdPartySaddleman(v-else-if='brandName === "Saddleman"' :params='integration.params')
ProductThirdPartyNorthwest(v-else-if='brandName === "Northwest"' :params='integration.params')
ProductThirdPartySeatCoverSolutions(v-else-if='brandName === "Seat Cover Solutions"' :params='integration.params')
iframe(v-else :src='iframeUrl' width='100%' height='900')
</template>

<script setup lang="ts">
const props = defineProps<{
  integration: OrderControlApiConfigIntegration
  brandName: string
}>()
/**
 * https://localhost:3000/p/skanda-ballistic-tactical-seat-covers/v/chevy/silverado-1500/2024/
 * https://localhost:3000/p/coverking-neoprene-seat-covers/v/ford/f150-lightning/2022/
 * https://localhost:3000/p/saddleman-saddle-blanket-seat-covers/v/ford/f150-lightning/2022/
 * https://localhost:3000/p/northwest-neo-ultra-seat-covers/v/ford/f150-lightning/2022/
 * https://localhost:3000/p/caltrend-camouflage-seat-covers/v/chevy/silverado-2500/2024/
 */
const iframeUrl = computed(() => {
  const url = props.brandName === 'CalTrend' ? 'caltrend' : 'configure-tech'
  return `/third-party/${url}/?params=${JSON.stringify(props.integration)}&brandName=${encodeURIComponent(
    props.brandName
  )}&disableIntegration=all`
})
</script>
